import {
  SET_LOGIN,
  SET_LOGOUT,
  SET_TOKEN,
  SET_USER_PROFILE,
  SET_SIGNUP_STEP_NO,
  SET_CHAT_TOKENS,
  SET_DIRECT_LOGIN_DATA,
} from "./loginActionType";
import { set_loader, unset_loader } from "../loader/loader_action";
import encrypt from "../../actions/shared/sharedActions";

import UNIVERSAL from "../../../config/config";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { setDashboardPage } from "../riskdashboard/riskDashboardActions";

export function setLogin(payload) {
  return {
    type: SET_LOGIN,
    payload: payload,
  };
}
export function setSignupStepNo(payload) {
  console.log("payload", payload);
  localStorage.setItem("signup_process_no", payload);
  return {
    type: SET_SIGNUP_STEP_NO,
    payload: payload,
  };
}

export function setToken(payload) {
  return {
    type: SET_TOKEN,
    payload: payload,
  };
}

export function setLogOut() {
  return {
    type: SET_LOGOUT,
  };
}

export function setUserProfile(payload) {
  return {
    type: SET_USER_PROFILE,
    payload: payload,
  };
}

export function setChatTokens(payload) {
  return {
    type: SET_CHAT_TOKENS,
    payload: payload,
  };
}

export function setDirectLoginData(payload) {
  return {
    type: SET_DIRECT_LOGIN_DATA,
    payload: payload,
  };
}

const loginHandler = ({ dispatch, responseJson }) => {
  dispatch(setSignupStepNo(4));
  localStorage.setItem("user_token", responseJson.result?.user_token);
  localStorage.setItem("bank_id", responseJson.result?.bank_id);
  localStorage.setItem("user_type", responseJson.result?.user_type);
  localStorage.setItem("userName", responseJson.result?.name);
  localStorage.setItem("is_premium", responseJson.result?.is_premium);
  localStorage.setItem(
    "chat_tour_completed",
    responseJson.result?.chat_tour_completed
  );
  localStorage.setItem(
    "company_tour_completed",
    responseJson.result?.company_tour_completed
  );

  localStorage.setItem(
    "profile_img",
    JSON.stringify(responseJson.result?.profile_img)
  );
  localStorage.setItem(
    "organization_data",
    JSON.stringify(responseJson.result?.organization_data)
  );
  localStorage.setItem(
    "organization_name",
    JSON.stringify(responseJson.result?.organization_name)
  );
  localStorage.setItem("user_id", responseJson.result?._id);
  localStorage.setItem("chat_tokens", responseJson.result?.chat_tokens || 0);

  localStorage.setItem("userEmail", responseJson.result?.email);
  localStorage.setItem("signup_process_no", 5);
  localStorage.setItem(
    "isDisclaimer",
    responseJson.result?.isDisclaimer || false
  );

  localStorage.setItem("appearance", responseJson.result?.appearance);
  localStorage.setItem("language", responseJson.result?.language);
  localStorage.setItem(
    "notifications",
    responseJson.result?.notifications || true
  );

  // dispatch(setChatTokens(responseJson.result?.tokens));

  dispatch(setLogin(responseJson.result));
};

export function direct_login({ accessCode, setHasErrror }) {
  const data = encrypt({
    access_code: accessCode,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/direct_login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          loginHandler({ dispatch, responseJson });
          const { isFrom, email } = responseJson?.result?.decodedToken;
          dispatch(setDirectLoginData({ isFrom, email }));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          setHasErrror(`Failed: ${responseJson.message}`);
        }
      })
      .catch((error) => {
        console.log(error);
        setHasErrror(`Failed: ${error.message}`);
      })
      .finally(dispatch(unset_loader()));
  };
}

export function login_with_email(email, password, captcha) {
  const data = encrypt({
    email,
    password,
    captcha,
  });
  return (dispatch) => {
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          loginHandler({ dispatch, responseJson });
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(dispatch(unset_loader()));
  };
}
export function gpt_signup(
  name,
  email,
  password,
  organization_name,
  designation,
  captcha
) {
  const data = encrypt({
    name,
    email,
    password,
    organization_name,
    designation,
    captcha,
  });
  // console.log(email, name, password, organization_name, designation);
  return (dispatch, getState) => {
    const { login } = getState();
    // console.log(state
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/add_gpt_user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson.status);
          dispatch(setSignupStepNo(2));
          localStorage.setItem("user_token", responseJson.result?.user_token);
          localStorage.setItem("bank_id", responseJson.result?.bank_id);
          localStorage.setItem("user_type", responseJson.result?.user_type);
          localStorage.setItem("userName", responseJson.result?.name);
          localStorage.setItem(
            "signup_process_no",
            responseJson.result?.signup_process_no
          );
          localStorage.setItem("is_premium", responseJson.result?.is_premium);

          localStorage.setItem(
            "profile_img",
            JSON.stringify(responseJson.result?.profile_img)
          );
          localStorage.setItem(
            "organization_data",
            JSON.stringify(responseJson.result?.organization_data) || `{}`
          );
          localStorage.setItem("user_id", responseJson.result?.user_id);
          localStorage.setItem("userEmail", email);

          localStorage.setItem("chat_tokens", responseJson.result?.tokens);

          localStorage.setItem("appearance", responseJson.result?.appearance);
          localStorage.setItem("language", responseJson.result?.language);
          localStorage.setItem(
            "notifications",
            responseJson.result?.notifications || true
          );

          dispatch(setLogin(responseJson.result));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        if (responseJson.result) {
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export const verifyOtp = (token, otp) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      otp,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/verify_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setSignupStepNo(3));
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
export const resendOtp = (token) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/resend_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // if (responseJson.status) {
        //   dispatch(setSignupStepNo(3));
        // }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
export function postSignupProcessNo(token, signup_process_no) {
  const data = encrypt({
    "user-token": token,
    signup_process_no,
  });
  return (dispatch, getState) => {
    const { login } = getState();
    // console.log(state
    dispatch(set_loader());
    return fetch(UNIVERSAL.BASEURL + "/users/set_signup_process_no", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson.status);
          // dispatch(setSignupStepNo(2));
          // localStorage.setItem("signup_process_no", 2);

          // dispatch(setLogin(responseJson.result));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        if (responseJson.result) {
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function logOut(token, isDisclaimer) {
  const data = encrypt({
    "user-token": token,
    isDisclaimer,
  });
  return (dispatch) => {
    dispatch(set_loader());
    localStorage.clear();
    return fetch(UNIVERSAL.BASEURL + "/users/logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          localStorage.clear();
          dispatch(setLogOut());
          dispatch(setDashboardPage(1));
        }
        dispatch(unset_loader());
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const forgetPassword = (email, domain_name) => {
  return (dispatch) => {
    dispatch(set_loader());

    let url = window.origin;
    let domain_name = window.location.hostname;
    const data = encrypt({
      email: email,
      // domain_name,
      // url,
    });
    return fetch(UNIVERSAL.BASEURL + "/users/forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};

export const resetForgetPassword = (password, reset_code) => {
  return (dispatch) => {
    dispatch(set_loader());

    const data = encrypt({
      password,
      reset_code,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/reset_forgot_password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          //
        }
        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
};
