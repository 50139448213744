import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import EsgGptDrawerTabs from "./EsgGptDrawerTabs";
import { Box, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

import company from "../../../images/EsgWiseNew.png";
import helpCenterIcon from "../../../images/help-circle.svg";
import settingSvg from "../../../images/settingButton.svg";
import bell from "../../../images/bell.png";

import Group from "../../../images/Group.svg";
import avatar from "../../../images/Avatar.svg";
import HelpCenterDrawer from "./esgHelpCenterDrawer/HelpCenterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { setHelpCenterDrawer } from "../../../redux/actions/gpt/esgGptAction";
import { logOut } from "../../../redux/actions/login/loginAction";
import UserProfileCard from "../userProfileCard/userProfileCard";
import TokenDetails from "./esgAppbarComps/TokenDetails";
import {
  setShowNotifications,
  setShowSettings,
} from "../../../redux/actions/gpt/appbarActions";
import SettingsDrawer from "./esgAppbarComps/SettingsDrawer/SettingsDrawer";
import NotificationsDrawer from "./esgAppbarComps/NotificationsDrawer/NotificationsDrawer";
import PlanExpired from "./PlanExpired";

const useStylesLayput = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "88vh",
    overflow: "hidden",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#EBF1F8",
    borderRadius: "0px 0px 8px 8px",
    boxShadow: "0px 1px 3px 1px rgba(51, 116, 185, 0.15)",
    height: "65px",
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(1),
  },

  chip: {
    backgroundColor: "#bd1010",
    width: "80px",
    height: "40px",
    display: "flex",
    justifyContent: "space-evenly",
    borderRadius: "900px",
    "&.MuiChip-colorPrimary": {
      backgroundColor: "#FEFEFE",
      color: "#15314E",
      fontSize: "18px",
      fontWeight: 500,
    },
    "&.MuiChip-root .MuiChip-avatarColorPrimary": {
      backgroundColor: "#FEFEFE",
      color: "#FEFEFE",
    },
  },
  detailChip: {
    backgroundColor: "#bd1010",
    width: "110px",
    height: "40px",
    display: "flex",
    justifyContent: "space-evenly",
    "&.MuiChip-colorPrimary": {
      backgroundColor: "#FEFEFE",
      color: "#15314E",
      fontSize: "18px",
      fontWeight: 500,
    },
    "&.MuiChip-root .MuiChip-avatarColorPrimary": {
      backgroundColor: "#FEFEFE",
      color: "#FEFEFE",
    },
  },
}));

function EsgGptAppBar() {
  const classes = useStylesLayput();

  // from redux
  const dispatch = useDispatch();
  const { helpCenter, showSettings, showNotifications } = useSelector(
    (state) => state?.esgGptReducer
  );
  const { chat_tokens, profile_img, direct_login } = useSelector(
    (state) => state?.login
  );

  // states
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [tokenDetail, setTokenDetail] = useState(false);
  const [planExpired, setPlanExpired] = useState(false);

  const togglePlanExpired = () => {
    setPlanExpired(!planExpired);
  };

  // ---------- inner functions ----------
  const toggleHelpCenter = () => {
    dispatch(setHelpCenterDrawer());
  };

  const handleMenuUser = (event) => {
    if (event?.currentTarget !== anchorElUser) {
      setAnchorElUser(event?.currentTarget);
    } else {
      setAnchorElUser(null);
    }
  };

  const handleUserLogOut = (token, value) => {
    dispatch(logOut(token, value));
  };

  // setting drawer toggle
  const toggleSettingsDrawer = () => {
    dispatch(setShowSettings(!showSettings));
  };

  // notifications drawer toggle
  const toggleNotificationsDrawer = () => {
    dispatch(setShowNotifications(!showNotifications));
  };
  // -------------------------------------
  const is_premium = JSON.parse(localStorage.getItem("is_premium"));
  const chipWidth = chat_tokens ? chat_tokens.toString().length * 10 + 70 : 50;
  return (
    <AppBar position="fixed" className={classes.appBar}>
      {planExpired && (
        <PlanExpired isOpen={planExpired} handleClose={togglePlanExpired} />
      )}
      <TokenDetails
        open={tokenDetail}
        openToken={() => setPlanExpired(true)}
        onClose={() => setTokenDetail(!tokenDetail)}
      />
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Box style={{ width: "350px", alignSelf: "flex-end" }}>
          <EsgGptDrawerTabs />
        </Box>
        <Box
          style={{
            height: "80%",
            alignSelf: "center",
          }}
        >
          <img src={company} alt="companyLogo" height={"100%"} />
        </Box>
        <Box
          style={{
            alignSelf: "right",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "300px",
            gap: 2,
          }}
        >
          <IconButton
            data-tut="help"
            aria-label="help_center"
            onClick={toggleHelpCenter}
          >
            <img
              src={helpCenterIcon}
              alt="companyLogo"
              height={"100%"}
              style={{ cursor: "pointer" }}
            />
          </IconButton>

          {direct_login?.isFrom !== "apurthi" && (
          <IconButton
            data-tut="setting"
            aria-label="setting"
            onClick={toggleSettingsDrawer}
          >
            <img
              src={settingSvg}
              alt="companyLogo"
              height={"100%"}
              style={{ cursor: "pointer" }}
            />
          </IconButton>
          )}

          <IconButton
            data-tut="notification"
            aria-label="notification"
            onClick={toggleNotificationsDrawer}
          >
            <img
              src={bell}
              alt="companyLogo"
              height={"100%"}
              style={{ cursor: "pointer" }}
              data-tut="notification"
            />
          </IconButton>

          {true && (
            <Chip
              className={classes.chip}
              data-tut="token"
              color="primary"
              label={chat_tokens || 0}
              avatar={<Avatar src={Group} />}
              onClick={() => setTokenDetail(!tokenDetail)}
              style={{ width: chipWidth, maxWidth: "100%", minWidth: "auto" }}
            />
          )}

          {direct_login?.isFrom !== "apurthi" && (
            <Avatar
              style={{ cursor: "pointer", marginLeft: "10px" }}
              src={profile_img}
              data-tut="user-profile"
              onClick={(e) => handleMenuUser(e)}
            />
          )}
        </Box>
      </Toolbar>

      {/* Help Center Drawer */}
      <HelpCenterDrawer open={helpCenter} onClose={toggleHelpCenter} />

      {/* logOut / user profile */}
      {anchorElUser && (
        <UserProfileCard
          profile_img={profile_img}
          anchorEl={anchorElUser}
          onClose={handleMenuUser}
          logOut={handleUserLogOut}
        />
      )}

      {/* Settings Drawer */}
      <SettingsDrawer open={showSettings} onClose={toggleSettingsDrawer} />

      {/* Notifications Drawer */}
      <NotificationsDrawer
        open={showNotifications}
        onClose={toggleNotificationsDrawer}
      />
    </AppBar>
  );
}

export default EsgGptAppBar;
