import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { direct_login } from "../../redux/actions/login/loginAction";
import { Box } from "@material-ui/core";

const AllowDirectCont = ({ accessCode }) => {
  const dispatch = useDispatch();

  const [hasError, setHasErrror] = useState("");

  useEffect(() => {
    if (accessCode) {
      dispatch(direct_login({ accessCode, setHasErrror }));
    } else {
      // clear localstorage and navigate user back to /login
      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessCode]);

  return (
    <Box
      p={2}
      width={"100%"}
      height={"70vh"}
      display={"flex"}
      justifyContent={"center"}
      bgcolor={"#cccccc80"}
    >
      {!hasError ? (
        <p>Redirecting...</p>
      ) : (
        <p>Unable to connect to GPT Server</p>
      )}
    </Box>
  );
};
export default AllowDirectCont;
