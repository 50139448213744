import {
  SET_LOGOUT,
  SET_LOGIN,
  SET_TOKEN,
  SET_USER_PROFILE,
  SET_SIGNUP_STEP_NO,
  SET_CHAT_TOKENS,
  SET_UPDATE_PROFILE,
  SET_DIRECT_LOGIN_DATA,
} from "../../actions/login/loginActionType.js";

const initial_state = {
  email: "",
  name: "",
  user_type: "",
  isLoggedIn: false,
  token: "",
  userProfile: "",
  user_id: "",
  bankId: "",
  organization_id: "",
  organization_data: {},
  organization_name: "",
  chat_tokens: 0,
  profile_img: "",
  appearance: "light",
  language: "english",
  notifications: true,
  signupStepNo: JSON.parse(localStorage.getItem("signup_process_no")) || 1,
  direct_login: null,
};

export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case SET_DIRECT_LOGIN_DATA:
      return (state = {
        ...state,
        direct_login: action.payload,
      });

    case SET_LOGIN:
      localStorage.setItem("organization_id", action.payload.organization_id);
      return (state = {
        ...state,
        token: action.payload.user_token,
        user_type: action.payload.user_type,
        isLoggedIn: true,
        name: action.payload.name,
        email: action.payload.email,
        userProfile: action.payload.profile_img,
        user_id: action.payload.user_id,
        bankId: action.payload.bank_id,
        organization_id: action.payload.organization_id,
        organization_data: action.payload.organization_data,
        signupStepNo: action?.payload?.signupStepNo || 1,
        organization_name: action?.payload?.organization_name,
        chat_tokens: action?.payload?.chat_tokens,
        profile_img: action?.payload?.profile_img,
        appearance: action?.payload?.appearance,
        language: action?.payload?.language,
        notifications: action?.payload?.notifications,

        // signupStepNo: action?.payload?.signupStepNo || localStorage.getItem("signup_process_no"),
      });

    case SET_TOKEN:
      return (state = {
        ...state,
        token: action.payload,
      });

    case SET_LOGOUT:
      return (state = {
        ...state,
        email: "",
        name: "",
        user_type: "",
        isLoggedIn: false,
        token: "",
        userProfile: "",
        user_id: "",
        organization_name: "",
      });

    case SET_USER_PROFILE:
      return (state = { ...state, userProfile: action.payload });
    case SET_SIGNUP_STEP_NO:
      return (state = { ...state, signupStepNo: action.payload });

    case SET_CHAT_TOKENS:
      return (state = { ...state, chat_tokens: action.payload });

    case SET_UPDATE_PROFILE:
      return (state = {
        ...state,
        name: action.payload.name,
        profile_img: action?.payload?.profile_img || "",
        appearance: action?.payload?.appearance,
        language: action?.payload?.language,
        notifications: action?.payload?.notifications,

        // signupStepNo: action?.payload?.signupStepNo || localStorage.getItem("signup_process_no"),
      });

    default:
      return state;
  }
}
