import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import NavCustomDrawer from "../UI/drawer/drawer";
import CustomAppBar from "../UI/appBar/appBar";
// import Login from "../../containers/resetPasswordCont/resetPasswordCont";
// import LoginCon from "../../containers/login/login-cont";
// import SurveyListingCont from "../../containers/surveyListing/surveyListingCont";
// import ReportListingCont from "../../containers/reportListing/reportListingCont";
// import ESGReportListingCont from "../../containers/esrReportListing/esgReportListingCont";
// import SingleESGReportCont from "../../containers/singleESGReport/sigleESGReportCont";
// import MetricReport from "../screen/singleESGReport/metricReport";
// import SustainabilityReportCont from "../../containers/SustainabilityReportMain/SustainabilityReportMainCont";
import "../router/router.css";
import MobileDrawer from "../UI/menuBar/menuBar";
// import OnboaringCon from "../../containers/onboarding/onboarding-cont";
// import signupContainer from "../../containers/signup/signupContainer";
import Controller_con from "../../containers/router/controllerCont";
import LoaderCon from "../../containers/loader/loader_cont";
import SnackbarCont from "../../containers/snackbar/snackbarCont";
// import EsgReport from "../widgets/esgReport";
// import Drawer from "../widgets/policies/drawer";
// import FAQ from "../widgets/policies/faq";
// import GeneralSettingsCont from "../../containers/generalSettings/generalSettingsCont";
// import ResetPasswordCon from "./../../containers/resetPasswordCont/resetPasswordCont";
// import OnboardinTour from "../screen/onboardinTour/onboardinTour";
// import BasicDetailsCont from "../../containers/basicDetails/basicDetailsCont";
// import GetStarted from "../screen/getStarted/getStarted";
// import SurveyUp from "../UI/maturityMatrix/SurveyUp";
// import Survey from "../UI/maturityMatrix/Survey";
// import GetStartedCont from "../../containers/getStarted/getStartedCont";
// import TnC from "../widgets/policies/T&C";
// import Privacy from "../widgets/policies/privacy";
// import DataHubCont from "../../containers/dataHub/dataHubCont";
// import DataHubSurveyCont from "../../containers/dataHub/dataHubSurveyCont";
// import AppDashBoard from "../UI/dataHubCalculatedCharts/AppDashBoard";
// import { DashBoardScreen } from "../screen/dashboard/dashboard";
// import DashboardCont from "../../containers/dashboard/dashboardCont";
// import riskAssessmentCont from "../../containers/riskAssessment/riskAssessmentCont";
// import BrmDetailsCont from "../../containers/riskAssessment/brmDetailsCont";
// import LoginCont from "../../containers/login/LoginCont";
// import CorporateSurveyTaskCont from "../../containers/corporateSurveyTaskCont/corporateSurveyTaskCont";
// import ResetPasswordCon from "../../containers/resetPasswordCont/resetPasswordCont";
// import RiskDashboardCont from "../../containers/riskDashboardCont/riskDashboardCont";
// import RiskDocument from "../widgets/riskDashboard/riskDocument";
// import riskDocumentCont from "../../containers/riskDashboardCont/riskDocumentCont";
// import EsgGptMain from "../screen/esgGpt/EsgGptMain";
import EsgGptCompanyProfiles from "../widgets/esgGpt/esgGptCompanyProfile/esgGptCompanyProfiles";
import EsgGptAddNewCompany from "../widgets/esgGpt/esgGptAddNewCompany/EsgGptAddNewCompany";
import EsgGptChat from "../widgets/esgGpt/esgGptChat/EsgGptChat";
import signupCont from "../../containers/signup/signupCont";
import PolicyDrawer from "../widgets/policies/policyDrawer";
import SignupCont from "../../containers/signup/signupCont";
import PaymentCont from "../../containers/PaymentCont/PaymentCont";
import queryString from "query-string";
import ResetPasswordCont from "../../containers/ResetPasswordCont/ResetPasswordCont";
import AllowDirectCont from "../../containers/AllowDirectCont/AllowDirectCont";

export const MContext = React.createContext();

class Router extends React.Component {
  autoMateLogout = () => {
    const { setLogOut, set_snack_bar } = this.props;
    localStorage.removeItem("user_token");
    setLogOut();
    set_snack_bar(true, "Please login again");
    return <Redirect to="/login" />;
  };

  state = {
    isOpen: false,
    updatePath: false,
    addNewCompany: false,
  };

  cb = (setIsOpen) => {
    this.setState({ isOpen: setIsOpen });
  };

  // componentDidUpdate() {
  //   if (
  //     false &&
  //     this.props.login.isLoggedIn === true &&
  //     window.location.pathname === "/add_company" &&
  //     !this.state.addNewCompany
  //   ) {
  //     this.setState({
  //       addNewCompany: true,
  //     });
  //   }
  // }

  render() {
    const { login, logOut } = this.props;
    // const userType = "gpt";
    const userType = localStorage.getItem("user_type");
    const signup_process_no = JSON.parse(
      localStorage.getItem("signup_process_no")
    );
    // if (snackbar.response_received && snackbar.message === "Invalid user") {
    // this.autoMateLogout();
    // }
    let body;

    // this will convert the string into objects
    const queryParams = queryString.parse(window.location.search);

    // Extracting the accessCode for direct login
    const accessCode = queryParams.accessCode;

    // Extracting the invoice number from the parsed query parameters
    const invoiceNumber = queryParams.invoice;

    // Extracting the reset code from the parsed query parameters
    const resetCode = queryParams.reset;
    const emailId = queryParams.email;

    const isDirectAccess = typeof accessCode !== "undefined";

    // Checking if the invoice number is present
    const isPaymentRoute = typeof invoiceNumber !== "undefined";

    // Checking if the reset code is present
    const isResetRoute =
      typeof resetCode !== "undefined" && typeof emailId !== "undefined";

    const completePaymentURL = `/complete_payment?invoice=${invoiceNumber}`;

    const resetPasswrodURL = `/reset_password?reset=${resetCode}&email=${emailId}`;

    const directAccessURL = `/allow_direct?accessCode=${accessCode}`;

    // if (true) {
    if (login.isLoggedIn === false && !localStorage.getItem("user_token")) {
      body = (
        <div>
          <Route path="/" component={Controller_con} />
          <Route exact path="/" component={signupCont} />
          <Route
            exact
            path="/login"
            render={() => <SignupCont onlyLogin={true} {...this.props} />}
          />
          {/* <Route path="/login" component={LoginCont} /> */}
          {/* <Route exact path="/reset_password" component={ResetPasswordCon} /> */}
          <Route
            exact
            path="/privacy_policy"
            render={() => <PolicyDrawer {...this.props} />}
          />
          <Route
            exact
            path="/faq"
            render={() => <PolicyDrawer {...this.props} />}
          />
          <Route
            exact
            path="/tnc"
            render={() => <PolicyDrawer {...this.props} />}
          />
          {window.location.pathname === "/privacy_policy" && (
            <Route render={() => <Redirect to="/privacy_policy" />} />
          )}
          {window.location.pathname === "/tnc" && (
            <Route render={() => <Redirect to="/tnc" />} />
          )}
          {window.location.pathname === "/faq" && (
            <Route render={() => <Redirect to="/faq" />} />
          )}

          {/* payment route */}
          <Route path="/complete_payment" render={() => <PaymentCont />} />

          {isPaymentRoute && (
            <Route render={() => <Redirect to={completePaymentURL} />} />
          )}

          {/* reset password route */}
          <Route path="/reset_password" render={() => <ResetPasswordCont />} />

          {isResetRoute && (
            <Route render={() => <Redirect to={resetPasswrodURL} />} />
          )}

          {/* Direct access route */}
          {isDirectAccess && (
            <Route render={() => <Redirect to={directAccessURL} />} />
          )}
          {/* payment route */}
          <Route
            path="/allow_direct"
            render={() => <AllowDirectCont accessCode={accessCode} />}
          />
        </div>
      );
    } else if (
      login.isLoggedIn === true ||
      localStorage.getItem("user_token")
    ) {
      body = (
        <Switch>
          <div
            className={
              signup_process_no > 3 &&
              window.location.pathname !== "/esg_gpt" &&
              window.location.pathname !== "/complete_payment" &&
              window.location.pathname !== "/reset_password"
                ? "div-post-login"
                : ""
            }
          >
            {/* {console.log(login.signupStepNo)} */}
            {userType !== "gpt" && (
              <NavCustomDrawer
                isOpen={this.isOpen}
                setIsOpen={this.cb}
                logOut={logOut}
                token={login.token}
                user_type={login.user_type}
                login={login}
                // redirect={surveyListing.redirect_value}
                setRedirectValue={this.props.setRedirectValue}
              />
            )}
            {userType !== "gpt" && (
              <CustomAppBar
                logOut={logOut}
                isOpen={this.state.isOpen}
                {...this.props}
              />
            )}
            {userType !== "gpt" && <MobileDrawer />}
            {/* <NavCustomDrawer
              isOpen={this.isOpen}
              setIsOpen={this.cb}
              logOut={logOut}
              token={login.token}
              user_type={login.user_type}
              login={login}
              // redirect={surveyListing.redirect_value}
              setRedirectValue={this.props.setRedirectValue}
            />
            <CustomAppBar
              logOut={logOut}
              isOpen={this.state.isOpen}
              {...this.props}
            />
            <MobileDrawer /> */}
            <Route path="/" component={Controller_con} />
            {/* <Route exact path="/esg_gpt" component={signupCont} /> */}
            {/* <Route exact path="/risk_analysis" component={riskAssessmentCont} />
            <Route exact path="/risk_admin" component={RiskDashboardCont} />
            <Route exact path="/risk_admin/doc" component={riskDocumentCont} />
            <Route
              exact
              path="/vendor"
              render={() => (
                <VendorOrganizationsCont isVendor={true} {...this.props} />
              )}
            />
            <Route
              path="/vendor/company_details"
              render={() => <VendorCont {...this.props} />}
            />
            <Route
              exact
              path="/risk_analysis/company_details"
              render={() => (
                <BrmDetailsCont isEvaluator={false} {...this.props} />
              )}
            />
            <Route
              exact
              path="/risk_evaluator/company_details"
              render={() => (
                <BrmDetailsCont isEvaluator={true} {...this.props} />
              )}
            />
            <Route
              exact
              path="/corporate_questions"
              component={CorporateCont}
            />
            <Route
              exact
              path="/corporate_questions/survey"
              render={() => (
                <CorporateSurveyTaskCont isEvaluator={true} {...this.props} />
              )}
            />
            <Route
              exact
              path="/risk_evaluator"
              component={CorporateEvaluatorCont}
            /> */}
            {/* Risk Survey Routes */}
            {/* <Route
              exact
              path="/risk_survey"
              component={RiskSurveyInnerContainer}
            />
           <Route
              exact
              path="/risk_survey/survey_details"
              component={RiskSurveyInnerContainer}
            /> */}

            <Route
              exact
              path="/esg_gpt"
              render={() => <SignupCont {...this.props} />}
            />

            <Route
              exact
              path="/esg_gpt/company_profiles"
              render={() => <EsgGptCompanyProfiles {...this.props} />}
            />

            <Route
              exact
              path="/esg_gpt/add_company"
              render={() => <EsgGptAddNewCompany {...this.props} />}
            />
            <Route
              exact
              path="/esg_gpt/chat"
              render={() => <EsgGptChat {...this.props} />}
            />
            {/* {userType === "RM" && (
              <Route render={() => <Redirect to="/risk_analysis" />} />
            )}
            {userType === "RE" && (
              <Route render={() => <Redirect to="/risk_evaluator" />} />
            )}
            {userType === "corp" && (
              <Route render={() => <Redirect to="/corporate_questions" />} />
            )}
            {userType === "RA" && (
              <Route render={() => <Redirect to="/risk_admin" />} />
            )} */}
            {userType === "gpt" && signup_process_no === 2 && (
              <Route render={() => <Redirect to="/esg_gpt" />} />
            )}
            {userType === "gpt" && signup_process_no > 4 && (
              <Route
                render={() => <Redirect to="/esg_gpt/company_profiles" />}
              />
            )}
            {/* {userType === "VA" && (
              <Route render={() => <Redirect to="/vendor" />} />
            )} */}
            <Route
              exact
              path="/privacy_policy"
              render={() => <PolicyDrawer {...this.props} />}
            />
            <Route
              exact
              path="/faq"
              render={() => <PolicyDrawer {...this.props} />}
            />
            <Route
              exact
              path="/tnc"
              render={() => <PolicyDrawer {...this.props} />}
            />
            {window.location.pathname === "/privacy_policy" && (
              <Route render={() => <Redirect to="/privacy_policy" />} />
            )}
            {window.location.pathname === "/tnc" && (
              <Route render={() => <Redirect to="/tnc" />} />
            )}
            {window.location.pathname === "/faq" && (
              <Route render={() => <Redirect to="/faq" />} />
            )}

            {/* <Route exact path="/drawer" component={Drawer} /> */}
            {/* <Route exact path="/tnc" component={FAQ} /> */}
            {window.location.pathname === "/risk_analysis" && (
              <Route render={() => <Redirect to="/risk_analysis" />} />
            )}
            {window.location.pathname === "/risk_evaluator" && (
              <Route render={() => <Redirect to="/risk_evaluator" />} />
            )}
            {window.location.pathname === "/corporate_questions" && (
              <Route render={() => <Redirect to="/corporate_questions" />} />
            )}

            {/* payment route */}
            <Route path="/complete_payment" render={() => <PaymentCont />} />

            {isPaymentRoute && (
              <Route render={() => <Redirect to={completePaymentURL} />} />
            )}

            {/* reset password route */}
            <Route
              path="/reset_password"
              render={() => <ResetPasswordCont />}
            />

            {isResetRoute && (
              <Route render={() => <Redirect to={resetPasswrodURL} />} />
            )}

            {/* Direct access route */}
            {isDirectAccess && (
              <Route render={() => <Redirect to={directAccessURL} />} />
            )}
            {/* payment route */}
            <Route
              path="/allow_direct"
              render={() => <AllowDirectCont accessCode={accessCode} />}
            />
          </div>
        </Switch>
      );
    }

    return (
      <BrowserRouter>
        <SnackbarCont />
        <LoaderCon {...this.props} />
        {body}
      </BrowserRouter>
    );
  }
}
export default Router;
