import CancelOutlined from "@material-ui/icons/CancelOutlined";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

export default function LoginController(props) {
  const { login, setLogin, getVersionNo, setSignupStepNo } = props;

  const [redirect, setRedirect] = useState(false);
  const [redirectToDetails, setRedirectToDetails] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [redirectToGetStarted, setRedirectToGetStarted] = useState(false);
  const [versionNo, setVersionNo] = useState(false);
  const [userType, setUserType] = useState("");
  const [redirectToOtp, setRedirectToOtp] = useState(false);
  const [redirectToTnc, setRedirectToTnc] = useState(false);

  const isKeepSignedIn = JSON.parse(localStorage.getItem("keep_me_signed_in"));

  const onIdle = () => {
    if (!isKeepSignedIn) {
      localStorage.clear();
      setRedirectToLogin(true);
      window.location.reload(true);
    }
  };
  const idleTimer = useIdleTimer({ onIdle, timeout: 20 * 60 * 1000 });

  const localStorageUserType = localStorage.getItem(
    "current_organisation_user_type"
  );

  const completedSteps = JSON.parse(localStorage.getItem("completed_steps"));

  useEffect(() => {
    setUserType(localStorageUserType);
  }, [localStorageUserType]);

  useEffect(() => {
    if (login.token === "" && localStorage.getItem("user_token") !== null) {
      if (login.token === "") {
        setSignupStepNo(
          JSON.parse(localStorage.getItem("signup_process_no")) || 0
        );
        const notificationsData = localStorage.getItem("notifications");
        setLogin({
          user_token: localStorage.getItem("user_token"),
          user_type: localStorage.getItem("user_type"),
          userName: localStorage.getItem("userName"),
          organization_id: localStorage.getItem("organization_id"),
          bank_id: localStorage.getItem("bank_id"),
          userEmail: localStorage.getItem("userEmail"),
          name: localStorage.getItem("userName"),
          email: localStorage.getItem("userEmail"),
          isDisclaimer: localStorage.getItem("isDisclaimer"),
          signupStepNo: JSON.parse(localStorage.getItem("signup_process_no")),
          userProfile:
            localStorage.getItem("userProfile") &&
            localStorage.getItem("userProfile") !== undefined &&
            (JSON.parse(localStorage.getItem("userProfile"))
              ? JSON.parse(localStorage.getItem("userProfile"))
              : ""),
          parent_organisation:
            localStorage.getItem("parent_organisation") &&
            JSON.parse(localStorage.getItem("parent_organisation")),
          profile_img: localStorage.getItem("profile_img"),
          currentOrganisationUserType: localStorage.getItem(
            "current_organisation_user_type"
          ),
          chat_tokens: localStorage.getItem("chat_tokens"),
          organization_data: JSON.parse(
            localStorage.getItem("organization_data")
          )
            ? JSON.parse(localStorage.getItem("organization_data"))
            : "",
          organization_name: JSON.parse(
            localStorage.getItem("organization_name")
          ),
          appearance: localStorage.getItem("appearance"),
          language: localStorage.getItem("language"),
          notifications:
            notificationsData && notificationsData !== "undefined"
              ? JSON.parse(localStorage.getItem("notifications"))
              : true,
          // JSON.parse(localStorage.getItem("notifications")),
        });
      }
    } else if (!login.token) {
      // setRedirectToGetStarted(true)
    }
    // else if (false &&
    //   login.token &&
    //   login.signupProcessNo === 4 &&
    //   completedSteps &&
    //   completedSteps.length !== 0
    // ) {
    //   setRedirect(true);
    //   setRedirectToDetails(false);
    //   setRedirectToGetStarted(false);
    // } else if (false &&
    //   login.token !== '' &&
    //   (login.signupProcessNo === 2 || login.signupProcessNo === 3)
    // ) {
    //   setRedirectToDetails(true);
    // } else if (false && login.token !== '' && login.signupProcessNo === 1) {
    //   setRedirectToOtp(true);
    // } else if (
    //   false &&  login.token &&
    //   login.signupProcessNo === 4 &&
    //   completedSteps &&
    //   completedSteps.length !== 0
    // ) {
    //   setRedirect(false);
    // }
  }, []);
  // useEffect(() => {
  //   if (false && login.token && completedSteps && completedSteps.length > 0) {
  //     setRedirectToGetStarted(false);
  //   } else if (
  //     login.token &&
  //     completedSteps &&
  //     completedSteps.length === 0 &&
  //     login.signupProcessNo === 4 &&
  //     userType === "SA"
  //   ) {
  //     setRedirectToGetStarted(true);
  //   }
  // }, [completedSteps]);

  // useEffect(() => {
  //   var hours = 2;
  //   var now = new Date().getTime();
  //   var setupTime = localStorage.getItem('setupTime');
  //   if (setupTime == null) {
  //     localStorage.setItem('setupTime', now);
  //   } else {
  //     if (now - setupTime > hours * 60 * 60 * 1000) {
  //       localStorage.clear();
  //       localStorage.setItem('setupTime', now);
  //       setRedirectToLogin(true);
  //     }
  //   }
  // }, [localStorage.getItem('setupTime')]);

  // useEffect(() => {
  //   if (localStorage.getItem('user_token')) {
  //     getVersionNo();
  //     if (login.versionNo) {
  //       let oldVersionNo = localStorage.getItem('version_no');
  //       if (oldVersionNo && oldVersionNo !== login.versionNo) {
  //         setVersionNo(true);
  //         localStorage.clear();
  //       }
  //     }
  //   }
  // }, [login.versionNo]);

  if (redirectToLogin || versionNo) {
    return <Redirect to="/login"></Redirect>;
  }

  if (redirectToDetails && !window.location.pathname === "/privacy_policy") {
    return <Redirect to="/basicDetails"></Redirect>;
  }

  if (redirectToGetStarted) {
    return <Redirect to="/get_started"></Redirect>;
  }

  if (
    redirect &&
    !login.isAddNewCompany &&
    login.tutorialCompletedSteps.length > 0 &&
    !window.location.pathname === "/privacy_policy"
  ) {
    if (userType === "A") {
      return <Redirect to="/report_listing" />;
    } else if (userType === "SA") {
      return <Redirect to="/survey_listing" />;
    }
  }
  // if (redirectToOtp && !window.location.pathname === '/privacy_policy') {
  //   return (
  //     <>
  //       <Redirect to="/" />
  //     </>
  //   );
  // }
  if (
    !props.isLoggedIn &&
    !localStorage.getItem("user_token") &&
    window.location.pathname !== "/reset_password" &&
    window.location.pathname !== "/"
  ) {
    return (
      <>
        <Redirect to="/login" />
      </>
    );
  }
  // console.log('redir-',redirect,"tnc-", redirectToTnc,'otp-',redirectToOtp ,'redirectToDetails',redirectToDetails);
  if (redirectToTnc) {
    return <Redirect to="/privacy_policy" />;
  }
  if (
    (localStorage.getItem("user_type") === null ||
      localStorage.getItem("user_type") === "") &&
    window.location.pathname !== "/login"
  ) {
    // return <Redirect to="/signup" />
    return null;
  }
  return <div></div>;
}
